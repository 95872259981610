<template>
    <v-app>
        <v-main class="bgMinsal">
            <v-container fill-height justify-center fluid style="position: relative;">
                <v-flex xs12 sm8 md6 lg6 xl3 style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                    <v-img
                        contain
                        class="mx-auto d-flex mb-6"
                        src="@/assets/img/comprasal_login.png"
                        max-width="380"
                    />
                    <v-card class="mt-10 px-2 px-sm-12 elevation-2">
                        <v-card-title class="justify-center secondary--text" primary-title>
                            Confirmación de reseteo de contraseña y generación de reporte
                        </v-card-title>
                        <v-card-text>
                            Si tienes indicios que alguien está tratando de acceder a tu cuenta haz click
                            en confirmar para reestablecer la contraseña de tu cuenta y generar un reporte
                            para dar seguimiento a estas incosistencias.
                        </v-card-text>
                        <v-card-actions class="justify-center pb-5" style="width: 100%; gap: 16px;">
                            <v-btn :to="{name:'login'}" class="secondary" :disabled="reset.isLoading">Ir al login</v-btn>
                            <v-btn color="primary" @click.stop="handlePasswordReset" :loading="reset.isLoading">Confirmar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';

    export default {
        data: () => ({
            token: null,
            reset: createLoadable(null),
        }),
        methods: {
            async handlePasswordReset() {
                console.log('reseteando la password');
                console.log('this.token', this.token);
                toggleLoadable(this.reset);
                const { data } = await this.services.Auth.resetPasswordAndReport(this.token);
                setLoadableResponse(this.reset, data, { showAlertOnSuccess: true });

                if (!isResponseSuccesful(data)) return;
                this.$router.push('/login');
            },
        },
        mounted() {
            this.token = this.$route.params.token;
        },
    }
</script>